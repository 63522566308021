import { MoNotificationBadge } from '@motivo/guanyin/src/components';
import { Box, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { useScrollToSearchParam } from '@motivo/guanyin/src/hooks/useScrollToSearchParam';

export type SettingsHeaderType = {
  hasMissingFields?: boolean;
} & TypographyProps;

export default function SettingsHeader({
  hasMissingFields = false,
  id,
  children,
  ...props
}: SettingsHeaderType) {
  return (
    <Typography ref={useScrollToSearchParam(id)} variant="h3" {...props}>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        {children} {hasMissingFields && <MoNotificationBadge />}
      </Box>
      {hasMissingFields && (
        <Typography variant="body2" color="error" fontWeight="bold">
          Please fill out the missing fields to complete your profile
        </Typography>
      )}
    </Typography>
  );
}
