import { createContext, useContext } from 'react';

interface MoFormContextProps {
  validateOnMount: boolean;
}

const MoFormContext = createContext<MoFormContextProps | undefined>(undefined);

export const useMoFormContext = () => {
  const context = useContext(MoFormContext);
  if (!context) {
    return { validateOnMount: false };
  }
  return context;
};

export default MoFormContext;
